/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card } from "antd";
import * as linkify from "linkifyjs";
import Linkify from "linkifyjs/react";
import Utils from "../utils/DisplayUtils";
import Env from "../utils/Env";
import { withRouter } from "react-router";
import UseMessageEditing from "../hooks/UseMessageEditing";
import MessageMetadata from "./MessageMetadata";
import MessageActivitySummary from "./MessageActivitySummary";
import MessageInput from "./MessageInput";
import "./Messages.css";

require("linkifyjs/plugins/mention")(linkify);

const Message = ({
  previousMessage,
  disableActions,
  message,
  embeddedMessage,
  handleClick,
  isEmbed,
  handleAvatarClick,
  handleHighlightClick,
  showMetadata = true,
  onMouseEnter,
  onMouseLeave,
  showDetail,
  isHovered,
  trustedLikes,
  history,
  conversationStatus,
  featured,
  appUser,
}) => {
  const likeCount = Object.values(message.likes || {}).length;
  const branchCount = Object.values(message.branches || {}).length;

  const user = appUser;

  const {
    setMessageEditing,
    editingState,
    setEdits,
    updateMessageInDb,
  } = UseMessageEditing(message, user);

  const { isEditing, messageBody, hasEdited, sendDisabled } = editingState;

  const countBranches = (branchCount) => {
    if (branchCount >= 1) {
      return "branch_message";
    }
    return null;
  };

  const messageStyles =
    isEmbed || showDetail ? [".embedded_message"] : countBranches(branchCount);

  const linkProps = {
    onClick: (e) => e.stopPropagation(),
  };
  const options = {
    attributes: linkProps,
    className: "linkified",
    defaultProtocol: "http",
    events: null,

    format(value, type) {
      if (embeddedMessage) {
        value = value.replace(
          value,
          `${process.env.REACT_APP_NETWORK_NAME}://+${message.uid}`
        );
      }
      return Utils.linkTrimmer(value);
    },
    formatHref(href, type) {
      if (embeddedMessage) {
        return href.replace(
          href,
          `${Env.baseUrl()}/${
            featured ? "public" : conversationStatus
          }/conversations/${embeddedMessage.conversation}`
        );
      }

      if (type === "mention") {
        const tagged = Object.values(message.tagged || {}).map((user) => user);

        if (tagged.length) {
          const user = tagged.filter((tag) =>
            href.split("/")[1].includes(tag.displayName.replace(/\s/g, ""))
          );
          if (user[0] !== undefined) {
            return href.replace(
              href,
              `${Env.baseUrl()}/profile/${user[0].uid}`
            );
          }
        }
      }

      return href;
    },
    ignoreTags: [],
    nl2br: false,
    tagName: "a",
    target: {
      url: "_blank",
    },
    validate: {
      email: false, // don't linkify emails
    },
  };

  if (!message.user || !message.body) return null;
  const baseSize = 22;

  const fontSizeChart = {
    1: `${baseSize + 1}px`,
    2: `${baseSize + 2}px`,
    3: `${baseSize + 3}px`,
    4: `${baseSize + 4}px`,
    5: `${baseSize + 5}px`,
  };

  const fontWeightChart = {
    1: "600",
    2: "600",
    3: "600",
    4: "700",
    5: "700",
  };

  const fontSize = likeCount
    ? fontSizeChart[likeCount] || `${baseSize + 6}px`
    : `${baseSize + 1}px`;

  const countLikes =
    likeCount >= 1 && !isEmbed
      ? {
          fontSize,
          letterSpacing: 0.05,
          fontFamily: "Crimson Text",
          fontWeight: `${fontWeightChart[likeCount]}`,
          color: "#030303",
        }
      : {
          fontSize,
          fontFamily: "Crimson Text",
          color: "#030303",
        };

  const editButton =
    isHovered &&
    message.user.uid === user.uid &&
    !(disableActions || isEmbed || embeddedMessage) ? (
      <span
        role="img"
        aria-label="emoji"
        className={trustedLikes.length >= 3 ? "highlightPadding" : "highlight"}
        onClick={(e) => {
          return e.stopPropagation(), setMessageEditing();
        }}
      >
        🛠
      </span>
    ) : null;

  const highlightButton =
    isHovered && !(disableActions || isEmbed || embeddedMessage) ? (
      <span
        role="img"
        aria-label="emoji"
        className={trustedLikes.length >= 3 ? "highlightPadding" : "highlight"}
        onClick={(e) => handleHighlightClick(e, message.uid)}
      >
        🔦
      </span>
    ) : null;

  const trustLikes = trustedLikes ? (
    <MessageActivitySummary trustedLikes={trustedLikes} />
  ) : null;

  return (
    <Card
      className={messageStyles}
      style={isHovered && !isEditing ? styles.hoveredMessage : null}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      bordered={false}
      bodyStyle={Object.assign(
        {},
        styles.baseMessage,
        isEmbed ? styles.embeddedMessage : {},
        showDetail ? { paddingLeft: 0, paddingRight: 0 } : {}
      )}
    >
      <MessageMetadata
        isEditing={isEditing}
        previousMessage={previousMessage}
        trustedLikes={trustedLikes}
        message={message}
        show={showMetadata}
        handleAvatarClick={handleAvatarClick}
      />

      <Row
        type="flex"
        align="top"
        onClick={() =>
          isEmbed
            ? handleClick(message, conversationStatus)
            : handleClick(message)
        }
      >
        <Col
          xs={showDetail ? { span: 24 } : { span: 18, offset: 3 }}
          sm={showDetail ? { span: 24 } : { span: 18, offset: 3 }}
          md={showDetail ? { span: 24 } : { span: 19, offset: 2 }}
          lg={showDetail ? { span: 24 } : { span: 19, offset: 2 }}
          style={{ paddingLeft: 0 }}
        >
          {isEditing ? (
            <MessageInput
              sendDisabled={sendDisabled}
              hasEdited={hasEdited}
              isEditing={isEditing}
              handleSetEdits={(e) => setEdits(e.target.value)}
              handleCancelEditing={() => {
                setMessageEditing().then(() => setEdits(message.body));
              }}
              handleUpdateMessage={() => updateMessageInDb()}
              inputValue={messageBody}
              user={message.user}
            />
          ) : (
            <div style={{ fontFamily: "Crimson Text", color: "#030303" }}>
              {message.type === "deleted_message"
                ? "This message has been deleted."
                : message.body
                    .replace("exp:", "")
                    .split("\n")
                    .map((line) => (
                      <Linkify
                        className="message-body"
                        tagName="p"
                        options={options}
                        style={Object.assign(countLikes, {
                          marginBottom: "10px",
                        })}
                      >
                        {line}
                      </Linkify>
                    ))}
            </div>
          )}
        </Col>
        <Col
          sm={1}
          style={{
            position: "relative",
            height: "32px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          {highlightButton}
        </Col>
        <Col
          sm={1}
          style={{
            position: "relative",
            height: "32px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          {editButton}
        </Col>
        <Col sm={1}>{trustLikes}</Col>
        {/* <Col>{JSON.stringify(editingState)}</Col> */}
      </Row>
    </Card>
  );
};

Message.propTypes = {
  message: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleHighlightClick: PropTypes.func.isRequired,
};

const styles = {
  itemIcon: {
    position: "relative",
    right: "10px",
    top: "0px",
  },
  hoveredMessage: {
    cursor: "pointer",
    backgroundColor: "#F9F9F9",
  },
  baseMessage: { paddingBottom: "10px", paddingTop: "10px" },
  embeddedMessage: { backgroundColor: "#FFEEEE", paddingTop: "16px" },
};

export default withRouter(Message);
