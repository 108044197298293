import * as firebase from "firebase";
import fetch, { Headers } from "node-fetch";
import { filter, map } from "rxjs/operators";
import { object } from "rxfire/database";

const initFirebaseApp = (x) => {
  if (!firebase.apps.length) {
    return firebase.initializeApp({
      apiKey: "AIzaSyCARg9Avy76s8k_KjOdVzP_LjLcfNl0aOA",
      authDomain: "media-test-a09a4.firebaseapp.com",
      databaseURL: "https://media-test-a09a4.firebaseio.com",
      projectId: "media-test-a09a4",
      storageBucket: "media-test-a09a4.appspot.com",
      messagingSenderId: "225066740816",
    });
  }
};
// firebase.database().ref(".info/connected");
const infoRef = () => firebase.database().ref(".info/connected");

const userRef = (id = "") => firebase.database().ref(`users/${id}`);

const fetchUser = () => {};

const logInAdmin = (x) =>
  x
    .auth()
    .signInAnonymously()
    .catch((err) => console.error(err))
    .then((res) => console.log("Success ", res));

const getTokenHeader = (x) =>
  firebase
    .auth()
    .currentUser.getIdToken()
    .then((idToken) => {
      const headers = new Headers();
      headers.append("Authorization", `Token ${idToken}`);
      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      return headers;
    });

const deleteUser = (email) => (headers) =>
  fetch(
    "https://us-central1-media-test-a09a4.cloudfunctions.net/api/removeTestUser",
    {
      method: "POST", // or 'PUT',
      headers,
      body: JSON.stringify(email),
    }
  ).then((response) => response);

const deleteConversation = (email) => (headers) =>
  fetch(
    "https://us-central1-media-test-a09a4.cloudfunctions.net/api/removeTestConversation",
    {
      method: "POST", // or 'PUT',
      headers,
      body: JSON.stringify(email),
    }
  ).then((response) => response);

const sessionListener = (uid) => {
  alert("session ending");
  const ref = infoRef();
  object(ref)
    .pipe(
      filter((c) => c.snapshot.val() === true),
      map((c) => console.log("THE CCC ", c) || c)
    )
    .subscribe((change) => {
      userRef(`${firebase.auth().currentUser.uid}/lastSession`)
        .onDisconnect()
        .set(firebase.database.ServerValue.TIMESTAMP);
    });
};

const authStateChanged = () => {};

const userProfileRef = () => {};

const signInAnon = () => {};

const signOut = () => {};

const signInWithRedirect = (provider) => {};

export {
  sessionListener,
  fetchUser,
  userProfileRef,
  authStateChanged,
  signOut,
  signInAnon,
  signInWithRedirect,
  getTokenHeader,
  logInAdmin,
  initFirebaseApp,
  deleteUser,
  deleteConversation,
};
