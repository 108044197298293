import { takeLatest, select, call, all, put } from "redux-saga/effects";
// import { eventChannel } from "redux-saga";
import {
  startUploadProfileImage,
  getRandomColor,
  getRandomAvatarImage,
} from "./onboarding-methods";
import {
  getUser,
  updateUserProfile,
} from "../../features/user-profile/user-profile-reducer";
import {
  uploadProfileImage,
  getProfileImageFile,
  reportUploadProfileImageSuccess,
  startUploadOfProfileImage,
  setProfileDownloadUrl,
  reportUploadProfileImageError,
  setDisplayName,
  reportSetDisplayNameSuccess,
  getDisplayName,
  reportOnboardingComplete,
  toggleOnBoardModal,
  getIsOnBoardModalOpen,
  getOnboardingComplete,
} from "./onboarding-reducer";

import {
  sendWelcomeScript,
  trustFeaturedUsers,
} from "../user-auth/user-auth-methods";

export const delay = (time) =>
  new Promise((resolve) => setTimeout(resolve, time));

export function* handleUploadProfileImage({ payload }) {
  try {
    yield put(uploadProfileImage(payload));
    const { uid } = yield select(getUser);
    const { originFileObj } = yield select(getProfileImageFile);
    if (originFileObj.size > 2000000) {
      throw new Error("file size cannot exceed 2mbs");
    }
    const url = yield call(startUploadProfileImage, uid, originFileObj);
    yield put(setProfileDownloadUrl(url));
    yield put(updateUserProfile({ photoURL: url }));
    yield put(reportUploadProfileImageSuccess());
  } catch (error) {
    yield put(reportUploadProfileImageError(error));
  }
}

export function* handleSetDisplayName() {
  try {
    const displayName = yield select(getDisplayName);
    yield put(updateUserProfile({ displayName: displayName }));
    yield put(reportSetDisplayNameSuccess());
    const { photoURL } = yield select(getUser);
    if (!photoURL) {
      const color = yield call(getRandomColor);
      const imageUrl = yield call(getRandomAvatarImage, color, displayName);
      yield put(setProfileDownloadUrl(imageUrl));
      yield put(updateUserProfile({ photoURL: imageUrl }));
      yield put(reportUploadProfileImageSuccess());
    }
  } catch (error) {
    console.log("oops!");
    // yield put(reportUploadProfileImageError(error));
  }
}

export function* handleCompleteOnboarding() {
  try {
    const complete = yield select(getOnboardingComplete);
    const isOpen = yield select(getIsOnBoardModalOpen);

    //getuser selector
    if (isOpen === true && complete === true) {
      yield call(delay, 2000);
      yield put(toggleOnBoardModal());
    }
  } catch (error) {
    console.log("ERROR ", error);
  }
}

export function* watchReportOnboardingComplete() {
  yield takeLatest(reportOnboardingComplete().type, handleCompleteOnboarding);
}

export function* watchUploadProfileImage() {
  yield takeLatest(startUploadOfProfileImage().type, handleUploadProfileImage);
}

export function* watchSetDisplayName() {
  yield takeLatest(setDisplayName().type, handleSetDisplayName);
}

export default function* onBoarding() {
  yield all([
    watchUploadProfileImage(),
    watchSetDisplayName(),
    watchReportOnboardingComplete(),
  ]);
}
