import * as firebase from "firebase";

const Initialize = {
  initApp: () =>
    firebase.initializeApp({
      apiKey: "AIzaSyA1j6soz3WkaIuBG8ZS7KI4qjZwic1HRYY",
      authDomain: "media-28d4a.firebaseapp.com",
      databaseURL: "https://media-28d4a.firebaseio.com",
      projectId: "media-28d4a",
      storageBucket: "media-28d4a.appspot.com",
      messagingSenderId: "60187844551",
    }),
};

export default Initialize;
