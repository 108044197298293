import React from "react";
import { Card } from "antd";
import ImageMessage from "../component/ImageMessage";
import SystemMessage from "../component/SystemMessage";
import MessageMetadata from "../component/MessageMetadata";
import UseMessageEditing from "../hooks/UseMessageEditing";
import MessageActionBar from "../component/MessageActionBar";
import MessageInput from "../component/MessageInput";
import { Icon } from "antd";
import Linkify from "linkifyjs/react";

const MessageDetail = ({
  message,
  conversation,
  handleAvatarClick,
  handleSystemMessageClick,
  user,
  canDelete,
  handleDeleteClick,
  handleHighlightClick,
}) => {
  const {
    setMessageEditing,
    editingState,
    setEdits,
    updateMessageInDb,
  } = UseMessageEditing(message, user);

  const { isEditing, messageBody, hasEdited, sendDisabled } = editingState;

  const messageTypes = {
    deleted_message: "This message has been deleted.",
    standard: `${message.user.displayName} sent this to`,
  };

  // const options = {
  //   attributes: linkProps,
  //   className: "linkified",
  //   defaultProtocol: "http",
  //   events: null,

  //   format(value, type) {
  //     if (embeddedMessage) {
  //       value = value.replace(value, `galley://+${message.uid}`);
  //     }
  //     return Utils.linkTrimmer(value);
  //   },
  //   formatHref(href, type) {
  //     if (embeddedMessage) {
  //       return href.replace(
  //         href,
  //         `${Env.baseUrl()}/${
  //           featured ? "public" : conversationStatus
  //         }/conversations/${embeddedMessage.conversation}`
  //       );
  //     }

  //     if (type === "mention") {
  //       const tagged = Object.values(message.tagged || {}).map((user) => user);

  //       if (tagged.length) {
  //         const user = tagged.filter((tag) =>
  //           href.split("/")[1].includes(tag.displayName.replace(/\s/g, ""))
  //         );
  //         if (user[0] !== undefined) {
  //           return href.replace(
  //             href,
  //             `${Env.baseUrl()}/profile/${user[0].uid}`
  //           );
  //         }
  //       }
  //     }

  //     return href;
  //   },
  //   ignoreTags: [],
  //   nl2br: false,
  //   tagName: "a",
  //   target: {
  //     url: "_blank",
  //   },
  //   validate: {
  //     email: false, // don't linkify emails
  //   },
  // };

  const systemMessage = {
    icon:
      conversation.status === "public" ? (
        <Icon type="clock-circle-o" />
      ) : (
        <Icon type="key" />
      ),
    body: messageTypes[message.type ? message.type : "standard"],
    conversationTitle: ` ${
      message.type !== "deleted_message" ? conversation.title : ""
    }`,
    type: "conversation",
  };

  const renderMessage =
    message.type === "image_message" ? (
      <ImageMessage
        crop={false}
        message={message}
        showMetadata={false}
        handleClick={() => {}}
        handleAvatarClick={this.handleAvatarClick}
      />
    ) : (
      <div>
        {isEditing ? (
          <MessageInput
            isMessageDetail={true}
            hasEdited={hasEdited}
            isEditing={isEditing}
            handleSetEdits={(e) => setEdits(e.target.value)}
            handleCancelEditing={() => {
              return setMessageEditing().then(() => setEdits(message.body));
            }}
            inputValue={messageBody}
          />
        ) : (
          <div style={{ fontFamily: "Crimson Text", color: "#030303" }}>
            {message.type === "deleted_message"
              ? "This message has been deleted."
              : message.body
                  .replace("exp:", "")
                  .split("\n")
                  .map((line) => (
                    <Linkify
                      className="message-body"
                      tagName="p"
                      options={""}
                      style={Object.assign(
                        {},
                        {
                          marginBottom: "40px",
                        }
                      )}
                    >
                      {line}
                    </Linkify>
                  ))}
          </div>
        )}
        <MessageActionBar
          user={user}
          sendDisabled={sendDisabled}
          handleUpdateMessage={() => updateMessageInDb()}
          isEditing={isEditing}
          hasEdited={hasEdited}
          handleBranchClick={() => {}}
          handleHighlightClick={handleHighlightClick}
          disabled={user.isAnonymous || message.type === "deleted_message"}
          canDelete={canDelete}
          handleDeleteClick={handleDeleteClick}
          conversation={conversation}
          message={message}
          handleSetMessageEditing={() => setMessageEditing()}
          handleCancelEditing={() => {
            return setMessageEditing().then(() => setEdits(message.body));
          }}
        />
        {/* ,{JSON.stringify(editingState)} */}
      </div>
    );

  return (
    <div>
      <SystemMessage
        isDetail={true}
        message={systemMessage}
        handleClick={handleSystemMessageClick}
      />
      <Card bordered={false}>
        <MessageMetadata
          isEditing={isEditing}
          isMessageDetail={true}
          detail
          message={message}
          handleAvatarClick={handleAvatarClick}
        />
        <div style={{ marginTop: "15px" }}>{renderMessage}</div>
      </Card>
    </div>
  );
};

export default MessageDetail;
