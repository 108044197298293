import React from "react";
import { Layout, Menu, Button, Icon, Dropdown, Badge } from "antd";
import { NavLink } from "react-router-dom";
import Utils from "../utils/DisplayUtils";
import Avatar from "./Avatar";
import Activity from "./Activity";
import Banner from "./Banner";

const { Header } = Layout;

const Nav = ({
  activity,
  isPrivate,
  title,
  pathname,
  conversation,
  history,
  handleActivityMenuClick,
  handleSignIn,
  handleTitleClick,
  handleSignOut,
  user,
  toggleModal,
  signOut,
}) => {
  const getNavStyles = (path) =>
    pathname === path
      ? `${
          isPrivate ? "white-underline" : "red-underline"
        } galley ant-menu-item-selected`
      : null;

  const newActivity = activity.filter((a) => a.timestamp > user.lastSession);

  const navStyles = isPrivate ? "incognito" : "public";

  const statusBadge = {
    open: "📣",
    closed: "👀",
    trusted: "🦉",
  };

  const badge = conversation ? statusBadge[conversation.contribution] : null;

  const titleStyle =
    title.type === "title"
      ? { ...styles.titleStyle, color: isPrivate ? "#fff" : "#ec502e" }
      : { ...styles.locationStyle, color: isPrivate ? "#fff" : "#000" };

  const subNav = title.text ? (
    <div className="galley-header-drop" style={styles.barStyle}>
      <div className="galley-header-drop-padder">
        <h1
          className={`${navStyles} subnav`}
          style={titleStyle}
          onClick={handleTitleClick}
        >
          {Utils.subNavTitleTrimmer(title.text)}
        </h1>
        <h1
          className={`${navStyles} mobile-subnav`}
          style={titleStyle}
          onClick={() => history.goBack()}
        >
          <div
            style={{
              position: "absolute",
              left: "10px",
              color: "rgb(236, 80, 46)",
              fontSize: "23px",
            }}
          >
            {"< "}
          </div>
          {Utils.subNavTitleTrimmer(title.text)}
        </h1>
      </div>
      {title.type === "title" ? (
        <div style={styles.icon}>
          <span
            role="img"
            aria-label="emoji"
            style={{ color: "white", width: 18, height: 45 }}
          >
            {conversation.status !== "private" && badge}
          </span>
        </div>
      ) : null}
    </div>
  ) : null;

  const mobilelogoStyle = () => {
    if (title.text && isPrivate) {
      return {
        color: "#BCBCBC",
        display: history.location.pathname.toString().includes("conversation")
          ? "none"
          : "block",
      };
    } else if (title.text && !isPrivate) {
      return {
        color: "#E8E8E8",
        display: history.location.pathname.toString().includes("conversation")
          ? "none"
          : "block",
      };
    }
  };

  const logoStyle = () => {
    if (title.text && isPrivate) {
      return {
        color: "#BCBCBC",
      };
    } else if (title.text && !isPrivate) {
      return {
        color: "#E8E8E8",
      };
    }
  };

  const handleActivityPress = (type, activityItem) => {
    const { message, conversation, user: activityUser } = activityItem;

    const types = {
      MessageDetail: () =>
        history.push(
          `/${
            conversation.featured ? "public" : conversation.status
          }/conversations/${conversation.uid}/message/${message.uid}`
        ),
      ConversationDetail: () =>
        history.push(
          `/${
            conversation.featured ? "public" : conversation.status
          }/conversations/${conversation.uid}/detail`
        ),
      UserProfile: () => history.push(`/profile/${activityUser.uid}`),
      Conversation: () =>
        history.push(
          `/${
            conversation.featured ? "public" : conversation.status
          }/conversations/${conversation.uid}`
        ),
    };

    return types[type]();
  };

  const userMenu = (
    <Menu>
      <Menu.Item>
        <NavLink to={`/profile/${user.uid}`}>Profile</NavLink>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item style={{ backgroundColor: "white", color: "#ec502e" }}>
        <Button id="signOutButton" onClick={() => signOut()} type="primary">
          Sign out
        </Button>
      </Menu.Item>
    </Menu>
  );

  const userAvatar = user.isAnonymous ? null : (
    <div id="signout">
      <Dropdown overlay={userMenu} placement="bottomCenter">
        <div>
          <Avatar imageURL={user ? user.photoURL : null} size="extraSmall" />
        </div>
      </Dropdown>
    </div>
  );

  const activityMenu = (
    <Menu
      style={{
        width: "450px",
        maxHeight: "600px",
        overflowY: "scroll",
        marginTop: "10px",
      }}
    >
      {activity.map((i) => (
        <Activity key={i.uid} handlePress={handleActivityPress} activity={i} />
      ))}
    </Menu>
  );

  const activityDropdown = user.isAnonymous ? null : (
    <div id="badgedrop">
      <Dropdown
        overlay={activityMenu}
        placement="bottomCenter"
        trigger="click"
        onClick={handleActivityMenuClick}
      >
        <Badge count={newActivity.length} overflowCount={9} offset={[10, 35]}>
          <div
            style={{
              width: "35px",
              height: "35px",
              borderRadius: "20px",
              border: `1.5px solid ${
                newActivity.length < 1 || isPrivate ? "#9E9E9E" : "#ec502e"
              }`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Icon
              type="bell"
              style={{
                color:
                  newActivity.length < 1 || isPrivate ? "#9E9E9E" : "#ec502e",
                fontSize: "18px",
                margin: "0px",
                height: 20,
                width: 20,
              }}
            />
          </div>
        </Badge>
      </Dropdown>
    </div>
  );

  const composeButton = (
    <Button
      onClick={user.isAnonymous ? toggleModal : () => history.push("/compose")}
      className="compose compose-button"
      id={user.isAnonymous ? "loginTopNav" : "compose"}
      style={{
        color: isPrivate || pathname.includes("compose") ? "#fff" : "#ec502e",
        borderColor: isPrivate ? "#fff" : "#ec502e",
        backgroundColor: pathname.includes("compose") ? "#ec502e" : "inherit",
      }}
      icon={user.isAnonymous ? null : "edit"}
    >
      {!user.isAnonymous ? "New Conversation" : "Sign in via email"}
    </Button>
  );

  const cName = subNav ? "galley-header" : "galley-header-drop";
  return (
    <div>
      <Header
        className="galley-header"
        style={{
          width: "100%",
          padding: "inherit",
          background: "none",
          marginBottom: title.text ? "60px" : "0px",
        }}
      >
        <Menu
          className={`${navStyles} ${cName} galley-NavStyle`}
          mode="horizontal"
          style={{ position: "fixed", width: "100%" }}
        >
          <Banner />
          <Menu.Item
            className={`${getNavStyles("/featured")} ${
              isPrivate ? "white-underline" : "red-underline"
            }`}
          >
            <NavLink className={navStyles} exact to="/featured">
              <Icon type="trophy" /> Featured
            </NavLink>
          </Menu.Item>
          <Menu.Item
            className={`${getNavStyles("/public")} ${
              isPrivate ? "white-underline" : "red-underline"
            }`}
          >
            <NavLink className={navStyles} exact to="/public">
              <Icon type="clock-circle-o" />
              Recent
            </NavLink>
          </Menu.Item>
          <Menu.Item
            className={`${getNavStyles("/private")} ${
              isPrivate ? "white-underline" : "red-underline"
            }`}
          >
            <NavLink className={navStyles} exact to="/private">
              <Icon type="key" />
              Private
            </NavLink>
          </Menu.Item>
          <Menu.Item
            style={{ float: "right" }}
            className="placeholder icon-menu secondaryItem"
          >
            {composeButton}
          </Menu.Item>
          <Menu.Item
            style={{ float: "right" }}
            className="icon-menu secondaryItem"
          >
            {userAvatar}
          </Menu.Item>
          <Menu.Item
            style={{ float: "right" }}
            className="icon-menu secondaryItem"
          >
            {activityDropdown}
          </Menu.Item>

          <h1
            onClick={() => history.push("/featured")}
            className={`${navStyles} header-logo-mobile`}
            style={mobilelogoStyle()}
          >
            Galley by CJR
          </h1>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              position: "absolute",
            }}
            className={`${navStyles} subnav`}
          >
            <h1
              onClick={() => history.push("/featured")}
              className={`${navStyles} header-logo-mobile`}
              style={mobilelogoStyle()}
            >
              Galley by CJR
            </h1>
            <h1
              onClick={() => history.push("/featured")}
              className={`${navStyles} header-logo`}
              style={logoStyle()}
            >
              Galley by CJR
            </h1>
            {subNav}
          </div>
        </Menu>
      </Header>
    </div>
  );
};
const styles = {
  icon: {
    marginLeft: "10px",
    fontSize: "18px",
  },
  barStyle: {
    borderTop: "1px solid rgba(233, 233, 233, 0.75)",
    clear: "both",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 60,
  },
  badgeContainer: {
    textAlign: "right",
  },
  titleStyle: {
    textAlign: "center",
    fontFamily: "Roboto",
    fontWeight: 900,
    fontSize: "18pt",
    margin: "inherit",
    cursor: "pointer",
  },
  locationStyle: {
    textAlign: "center",
    fontFamily: "Roboto",
    fontWeight: 900,
    fontSize: "18pt",
    fontStyle: "bold",
    margin: "inherit",
    cursor: "pointer",
  },
};

export default Nav;
