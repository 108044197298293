import React from "react";
import OnboardingModal from "./OnboardingModal";

// eslint-disable-next-line react/display-name
const WithOnBoarding = (Component) => (props) => {
  return (
    <React.Fragment>
      <OnboardingModal {...props} />
      <Component {...props} />
    </React.Fragment>
  );
};

export default WithOnBoarding;
