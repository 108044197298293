import { useEffect } from "react";
import {
  initialState,
  setConversation,
  setConversationHost,
  setAccess,
  reducer,
} from "../reducers/message-input-reducer";
import { useThunkReducer } from "./UseThunkReducer";

const UseMessageInputCheck = (user, conversation, trustees) => {
  const [InputRequestWallState, dispatch] = useThunkReducer(
    reducer,
    initialState
  );

  const dispatchSetConversation = ({ user, conversation, trustees }) => {
    return dispatch(setConversation({ user, conversation, trustees }));
  };

  const dispatchSetConversationHost = () => {
    return dispatch(setConversationHost());
  };

  const dispatchSetAccess = () => {
    return dispatch(setAccess());
  };

  const dataRecieved = (user, conversation, trustees) => {
    Promise.all([
      dispatchSetConversation({ user, conversation, trustees }),
      dispatchSetConversationHost(),
      dispatchSetAccess(),
    ]);
  };

  const { isHost, hasAccess } = InputRequestWallState;

  useEffect(() => {
    if (trustees !== undefined) {
      dispatch(dataRecieved(user, conversation, trustees));
    }
  }, [trustees, conversation, user]);

  return {
    isHost,

    InputRequestWallState,
    hasAccess,
  };
};

export default UseMessageInputCheck;
