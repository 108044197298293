import { select, call, all, takeEvery, take, put } from "redux-saga/effects";
import firebase from "firebase/app";

import { eventChannel } from "redux-saga";

import {
  reportUploadProfileImageSuccess,
  reportSetDisplayNameSuccess,
} from "../onboarding/onboarding-reducer";
import { updateUserInDB } from "./user-profile-methods";
import { signOut } from "../user-auth/user-auth-reducer";
import {
  sendWelcomeScript,
  trustFeaturedUsers,
} from "../user-auth/user-auth-methods";
import {
  getUser,
  setUser,
  initializeUserProfile,
} from "./user-profile-reducer";

import { reportOnboardingComplete } from "../onboarding/onboarding-reducer";

const userRef = (id = "") => firebase.database().ref(`users/${id}`);

export function* handleUpdateUserInDB() {
  try {
    const user = yield select(getUser);
    yield call(updateUserInDB, user);
  } catch (error) {
    console.log("that didn't work! ", error);
  }
}

export function* handleUpdateAndSendWelcome() {
  try {
    const user = yield select(getUser);
    yield call(updateUserInDB, user);
    yield call(sendWelcomeScript, user.uid);
    yield call(trustFeaturedUsers, user);
  } catch (error) {
    console.log("that didn't work! ", error);
  }
}

export function* watchUploadProfileImageSuccess() {
  yield takeEvery(reportUploadProfileImageSuccess().type, handleUpdateUserInDB);
}

export function createUserUpdatesChannel(user) {
  //listens to any updates on the user object
  const { uid } = user;
  return eventChannel((emit) => {
    userRef(uid).on("value", (cs) => emit(cs.val()));

    return () => {
      userRef.close();
    };
  });
}

export function* handleWatchUserUpdateChannel() {
  const user = yield select(getUser);
  const channel = yield call(createUserUpdatesChannel, user);

  try {
    while (true) {
      const user = yield take(channel);
      if (user !== null) {
        const { displayName } = user;
        if (displayName) {
          yield put(reportOnboardingComplete(true));
        } else {
          yield put(reportOnboardingComplete(false));
        }
        yield put(setUser(user));
      } else {
        yield put(signOut());
      }
    }
  } catch (error) {
    console.log("oops! ", error);
  } finally {
    console.log("event channel terminated");
  }
}

export function* watchInitializeUserProfile() {
  yield takeEvery(initializeUserProfile().type, handleWatchUserUpdateChannel);
}

export function* watchSetDisplayNameSuccess() {
  yield takeEvery(
    reportSetDisplayNameSuccess().type,
    handleUpdateAndSendWelcome
  );
}
export default function* userProfile() {
  yield all([
    watchUploadProfileImageSuccess(),
    watchSetDisplayNameSuccess(),
    watchInitializeUserProfile(),
  ]);
}
