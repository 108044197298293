import React, { useReducer, useEffect } from "react";
import { Col, Row } from "antd";
import Avatar from "./Avatar";
import Utils from "../utils/DisplayUtils";
import MessageActivitySummary from "./MessageActivitySummary";
import { reducer, setMetaData } from "./MessageMetadataReducer";

const MessageMetadata = ({
  showActivity,
  message,
  handleAvatarClick,
  className,
  isEditing,
  action,
  isInput,
  show,
  trustedLikes,
  previousMessage,
  isMessageDetail,
  detail = false,
}) => {
  const [state, dispatch] = useReducer(reducer, { arr: [] });

  useEffect(() => {
    const messageMetadata = {
      message,
      previousMessage,
      isMessageDetail,
      isInput,
    };
    dispatch(setMetaData(messageMetadata));
  }, [message, previousMessage, isMessageDetail, isInput]);

  const { likes, branches, user, timestamp } = message;
  const likeCount = Object.values(likes || {}).length;
  const branchCount = Object.values(branches || {}).length;

  const userMetadata = detail ? (
    <React.Fragment>
      <Col lg={20} sm={20} xs={20} md={20}>
        <span style={styles.messageImageMetadataUserName}>
          {user.displayName}
        </span>
        <span style={styles.messageImageMetadataMessageTime}>
          {timestamp ? Utils.timeStamp(timestamp) : ""}{" "}
          {isEditing ? " • editing" : ""}
        </span>
      </Col>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Col lg={20} sm={20} xs={20} md={20}>
        <span
          style={styles.messageImageMetadataUserName}
          onClick={() => handleAvatarClick(message.user.uid)}
        >
          {user.displayName}
        </span>

        <span style={styles.messageImageMetadataMessageTime}>
          {timestamp ? Utils.timeStamp(timestamp) : ""}{" "}
          {isEditing ? " • editing" : ""}
        </span>
      </Col>
    </React.Fragment>
  );

  return state.shouldDisplay ? (
    // <div className="messageImageMetadataContainer">
    <Row style={{ display: "flex", alignItems: "center" }}>
      {detail || isInput ? (
        <Col lg={2} md={2} sm={3} xs={3} style={styles.messageAvatar}>
          <Avatar
            className={className}
            size="small"
            imageURL={user.photoURL}
            handleClick={() => handleAvatarClick(message.user.uid)}
          />
        </Col>
      ) : (
        <Col lg={2} md={2} sm={3} xs={3} style={styles.messageAvatar}>
          <Avatar
            className={className ? className : "convo-avatar"}
            size="small"
            imageURL={user.photoURL}
            handleClick={() => handleAvatarClick(message.user.uid)}
          />
        </Col>
      )}

      <MessageActivitySummary
        style={styles.messageImageActivity}
        likeCount={likeCount}
        branchCount={branchCount}
        display={showActivity}
      />
      {userMetadata}

      <Col sm={1} lg={1} md={1} xs={1} style={{ alignSelf: "center" }}>
        {action}
      </Col>
    </Row>
  ) : // </div>
  null;
};

export default MessageMetadata;

const styles = {
  imageContainer: {
    maxHeight: "500px",
    alignItems: "center",
    display: "flex",
    overflow: "hidden",
    width: "100%",
  },
  imageMessage: {
    width: "100%",
  },
  separatorStyle: {
    paddingTop: "7px",
    marginLeft: "11px",
    marginRight: "11px",
    borderTopWidth: "1px",
    paddingBottom: "13px",
  },

  messageImageMetadata: {
    // display: 'flex',
    // alignItems: 'flex-start',
    paddingTop: "2px",
    marginTop: "5px",
    marginBottom: "5px",
  },
  messageImageMetadataUserName: {
    fontFamily: "Roboto",
    fontWeight: 600,
    // display: 'flex',
    padding: "0px 20px 0px 0px",
    margin: "0px",
    fontSize: "18px",
    lineHeight: "18px",
    cursor: "pointer",
  },
  messageImageMetadataMessageTime: {
    // display: 'flex',
    color: "#AAAAAA",
    fontSize: "12px",
    lineHeight: "18px",
    fontFamily: "Roboto",
    fontWeight: 400,
    margin: "0px",
    alignSelf: "flex-start",
  },
  messageDetailMetadata: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  messageMetadata: {
    // display: 'flex',
    // flexDirection: 'row',
    width: "100%",
  },
};
